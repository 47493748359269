import I18N from 'core/i18n';
import FormUpdate from './formUpdate';
import $ from 'jquery';

const ATTR_CALCULATE_PRICE_URL = 'data-calculate-price-url';

class PriceWidget {
  constructor($modal) {
    new PriceControl($modal);
    new VatRate($modal);
  }
}

class CalculateAmountFormUpdate extends FormUpdate {
  handleUpdate(values) {
    super.handleUpdate(values, false); // prevent change to avoid endless update loop
    this.$form.trigger('rp-form-update:updated');
  }
}

class PriceControl {
  constructor($modal) {
    this.$modal = $modal;

    this.$fieldWithVat = $modal.find('#price-with-vat');
    const urlWithVat = this.$fieldWithVat.attr(ATTR_CALCULATE_PRICE_URL);
    this.$formUpdateByAmountWithVat = new CalculateAmountFormUpdate(this.$fieldWithVat, urlWithVat);

    this.$fieldWithoutVat = $modal.find('#price-without-vat');
    const urlWithoutVat = this.$fieldWithoutVat.attr(ATTR_CALCULATE_PRICE_URL);
    this.$formUpdateByAmountWithoutVat = new CalculateAmountFormUpdate(this.$fieldWithoutVat, urlWithoutVat);

    this.$lockedButton = $modal.find('#locked-button');
    this.$unlockedButton = $modal.find('#unlocked-button');
    this.$fieldUnlocked = $modal.find('#vat-rate-unlocked');

    this.$fieldWithVatHelpInput = $modal.find('#price-with-vat-help');
    this.$fieldWithoutVatHelpInput = $modal.find('#price-without-vat-help');
    this.$fieldWithVatHelpText = this.$fieldWithVat.closest('.form-group').find('.form-help');
    this.$fieldWithoutVatHelpText = this.$fieldWithoutVat.closest('.form-group').find('.form-help');

    this.init();
    this.bindEvents();
  }

  init() {
    this.handleUnlock(false);
  }

  bindEvents() {
    this.$lockedButton.on('click', () => this.handleUnlock(!this.unlocked));
    this.$unlockedButton.on('click', () => this.handleUnlock(!this.unlocked));
    this.$fieldWithVat.on('change', () => this.$formUpdateByAmountWithVat.handle());
    this.$fieldWithoutVat.on('change', () => this.$formUpdateByAmountWithoutVat.handle());

    this.$modal.on('rp-form-update:updated', () => {
      this.handleHelpText(this.$fieldWithVatHelpText, this.$fieldWithVatHelpInput.val());
      this.handleHelpText(this.$fieldWithoutVatHelpText, this.$fieldWithoutVatHelpInput.val());
    });
    this.$fieldWithVatHelpText.on('click', 'a', event => {
      event.preventDefault();
      this.handleHelpTextClick(this.$fieldWithVat, this.$fieldWithVatHelpInput.val());
    });
    this.$fieldWithoutVatHelpText.on('click', 'a', event => {
      event.preventDefault();
      this.handleHelpTextClick(this.$fieldWithoutVat, this.$fieldWithoutVatHelpInput.val());
    });
  }

  handleUnlock(unlocked) {
    this.unlocked = unlocked;
    this.$fieldUnlocked.val(this.unlocked);

    this.toggleButton(this.$lockedButton, !this.unlocked);
    this.toggleButton(this.$unlockedButton, this.unlocked);
  }

  toggleButton(button, show) {
    button.hidden(!show);
    button.disabled(!show);
  }

  handleHelpText($helpText, value) {
    $helpText.empty();

    if (value) {
      $helpText.append(`<span class="text-danger">${I18N.t('deal_payments_prescribed_amount_help')}: </span>`);
      $helpText.append(`<a href="#">${value}</a>`);
    }
  }

  handleHelpTextClick($input, value) {
    this.$fieldWithVatHelpInput.val('');
    this.$fieldWithoutVatHelpInput.val('');
    this.$fieldWithVatHelpText.empty();
    this.$fieldWithoutVatHelpText.empty();

    if (value) {
      $input.val(value).trigger('change');
    }
  }
}

class VatRate {
  constructor($modal) {
    this.$vatRate = $modal.find('#vat-rate');
    const urlVatRate = this.$vatRate.attr(ATTR_CALCULATE_PRICE_URL);
    this.$formUpdateByVatRate = new CalculateAmountFormUpdate(this.$vatRate, urlVatRate);

    this.bindEvents();
  }

  bindEvents() {
    this.$vatRate.on('change', () => this.$formUpdateByVatRate.handle());
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.find('.rp-price-widget').exists()) {
      new PriceWidget(data.$root);
    }
  });
});